<template>
    <div class="">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">
                                {{ $t('message.clients') }}
                            </div>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <el-input
                                        :placeholder="$t('message.search')"
                                        prefix-icon="el-icon-search"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        v-model="filterForm.search"
                                        size="small"
                                        round
                                        style="width: 100% !important"
                                        clearable
                                    ></el-input>
                                </div>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="12" class="flex-style text-right">
                        <crm-create-and-column-settings
                            :permission="$options.name"
                            :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                            @c-create="drawerCreate = true"
                            :columns="columns"
                            @c-change="updateColumn"
                        >
                        </crm-create-and-column-settings>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div class="card-table-header table-crm-smart custom__scroll">
                <table
                    class="table-my-code table-bordered"
                    v-loading="loadingData"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                >
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>
                            <th class="w50p" v-if="columns.custom_id.show">
                                {{ columns.custom_id.title }}
                            </th>
                            <th v-if="columns.clientTypes.show">
                                {{ columns.clientTypes.title }}
                            </th>

                            <th v-if="columns.name.show">
                                {{ columns.name.title }}
                            </th>

                            <th v-if="columns.phone.show">
                                {{ columns.phone.title }}
                            </th>

                            <th v-if="columns.email.show">
                                {{ columns.email.title }}
                            </th>

                            <th v-if="columns.type_id.show">
                                {{ columns.type_id.title }}
                            </th>

                            <th v-if="columns.location.show">
                                {{ columns.location.title }}
                            </th>

                            <th v-if="columns.inn.show">
                                {{ columns.inn.title }}
                            </th>

                            <th v-if="columns.gender_id.show">
                                {{ columns.gender_id.title }}
                            </th>

                            <th v-if="columns.passport_iin.show">
                                {{ columns.passport_iin.title }}
                            </th>

                            <th v-if="columns.passport.show">
                                {{ columns.passport.title }}
                            </th>

                            <th v-if="columns.mfo.show">
                                {{ columns.mfo.title }}
                            </th>

                            <th v-if="columns.okonx.show">
                                {{ columns.okonx.title }}
                            </th>

                            <th v-if="columns.oked.show">
                                {{ columns.oked.title }}
                            </th>

                            <th v-if="columns.rkp_nds.show">
                                {{ columns.rkp_nds.title }}
                            </th>

                            <th v-if="columns.country_id.show">
                                {{ columns.country_id.title }}
                            </th>

                            <th v-if="columns.city_id.show">
                                {{ columns.city_id.title }}
                            </th>

                            <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.id"
                                    :placeholder="columns.id.title"
                                    class="id_input"
                                ></el-input>
                            </th>

                            <th v-if="columns.custom_id.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.custom_id"
                                    :placeholder="columns.custom_id.title"
                                    class="id_input"
                                ></el-input>
                            </th>

                            <th v-if="columns.clientTypes.show">
                                <select-client-by-client-type
                                    :size="'mini'"
                                    :placeholder="columns.clientTypes.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :id="filterForm.clientType_id"
                                    v-model="filterForm.clientType_id"
                                >
                                </select-client-by-client-type>
                            </th>

                            <th v-if="columns.name.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.name"
                                    :placeholder="columns.name.title"
                                >
                                </el-input>
                            </th>
                            <th v-if="columns.phone.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.phone"
                                    :placeholder="columns.phone.title"
                                >
                                </el-input>
                            </th>
                            <th v-if="columns.email.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.email"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.email.title"
                                ></el-input>
                            </th>
                            <th v-if="columns.type_id.show">
                                <select-client-type
                                    :size="'mini'"
                                    :placeholder="columns.type_id.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :id="filterForm.type_id"
                                    v-model="filterForm.type_id"
                                >
                                </select-client-type>
                            </th>
                            <th v-if="columns.location.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.location"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.location.title"
                                ></el-input>
                            </th>
                            <th v-if="columns.inn.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.inn"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.inn.title"
                                ></el-input>
                            </th>
                            <th v-if="columns.gender_id.show">
                                <select-client-gender
                                    :size="'mini'"
                                    :placeholder="columns.gender_id.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :id="filterForm.gender"
                                    v-model="filterForm.gender"
                                >
                                </select-client-gender>
                            </th>

                            <th v-if="columns.passport_iin.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.passport_iin"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.passport_iin.title"
                                ></el-input>
                            </th>

                            <th v-if="columns.passport.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.passport"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.passport.title"
                                ></el-input>
                            </th>
                            <th v-if="columns.mfo.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.mfo"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.mfo.title"
                                ></el-input>
                            </th>
                            <th v-if="columns.okonx.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.okonx"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.okonx.title"
                                ></el-input>
                            </th>
                            <th v-if="columns.oked.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.oked"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.oked.title"
                                ></el-input>
                            </th>
                             <th v-if="columns.rkp_nds.show">
                                <el-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.rkp_nds"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.rkp_nds.title"
                                ></el-input>
                            </th>
                            <th v-if="columns.country_id.show">
                                <select-country
                                    :placeholder="columns.country_id.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.country_id"
                                ></select-country>
                            </th>

                            <th v-if="columns.city_id.show">
                                <select-city
                                    :placeholder="columns.city_id.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.city_id"
                                ></select-city>
                            </th>

                            <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th>
                        </tr>
                    </thead>

                    <transition-group name="flip-list" tag="tbody">
                        <tr
                            v-for="client in list"
                            :key="client.id"
                            class="cursor-pointer"
                            @dblclick="getSelectedForShow(client)"
                        >
                            <td v-if="columns.id.show">{{ client.id }}</td>
                            <td v-if="columns.custom_id.show">{{ client.custom_id }}</td>
                            <td v-if="columns.clientTypes.show">
                                <div
                                    v-for="client_type in client.clientTypes"
                                    :key="client_type.id"
                                >
                                    {{ client_type ? client_type.name : "" }},
                                </div>
                            </td>
                            <td v-if="columns.name.show">
                                <span @click="getSelectedForShow(client)" style="cursor: pointer; color: #0d67c9;">{{ client.name }}</span>
                            </td>
                            <td v-if="columns.phone.show">
                                {{ client.phone }}
                            </td>
                            <td v-if="columns.email.show">
                                {{ client.email }}
                            </td>
                            <td v-if="columns.type_id.show">
                                {{ client.type ? client.type.name : "" }}
                            </td>

                            <td v-if="columns.location.show">
                                {{ client.location }}
                            </td>
                            <td v-if="columns.inn.show">
                                {{ client.inn }}
                            </td>
                            <td v-if="columns.gender_id.show">
                                {{ client.gender ? client.gender.name : "" }}
                            </td>
                            <td v-if="columns.passport_iin.show">
                                {{ client.passport_iin }}
                            </td>
                            <td v-if="columns.passport.show">
                                {{ client.passport_series }} {{ client.passport_number }}
                            </td>
                            <td v-if="columns.mfo.show">
                                {{ client.mfo }}
                            </td>
                            <td v-if="columns.okonx.show">
                                {{ client.okonx }}
                            </td>
                            <td v-if="columns.oked.show">
                                {{ client.oked }}
                            </td>
                            <td v-if="columns.rkp_nds.show">
                                {{ client.rkp_nds }}
                            </td>
                            <td v-if="columns.country_id.show">
                                {{ client.country ? client.country.name : "" }}
                            </td>

                            <td v-if="columns.city_id.show">
                                {{ client.city ? client.city.name : "" }}
                            </td>

                            <td v-if="columns.settings.show" class="settings-td">
                                <el-dropdown @command="handleCommand">
                                    <span class="el-dropdown-link more_icons">
                                        <i class=" el-icon-arrow-down el-icon-more-outline"></i>
                                    </span>
                                    <el-dropdown-menu  slot="dropdown" size="mini" >
                                        <el-dropdown-item v-if="permissions.some(per => per.slug == 'clients.update')" :command="{ action: 'edit', model: client }" icon="el-icon-edit el-icon--left">       
                                            {{ $t("message.update") }}
                                        </el-dropdown-item>
                                        <el-dropdown-item v-if="permissions.some(per => per.slug == 'clients.delete')" :command="{ action: 'delete', model: client }" icon="el-icon-delete el-icon--left">
                                            {{ $t("message.delete") }}
                                        </el-dropdown-item>
                                        <el-dropdown-item v-if="client.clientTypes.map(el => el.id).includes(1)" :command="{ action: 'price_list', model: client }" icon="el-icon-money el-icon--left">
                                            {{ $t("message.price_list") }}
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </td>
                        </tr>
                    </transition-group>
                </table>
                <div class="my___pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"
                    ></crm-pagination>
                    <!-- <Pagination /> -->
                </div>
            </div>

            <div class="app-modal app-modal__full modal-color-bg">
                <el-drawer
                    :with-header="false"
                    :visible.sync="drawerCreate"
                    ref="drawerCreate"
                    size="70%"
                    class="body_scroll_70" 
                    @opened="drawerOpened('drawerCreateChild')"
                    @closed="drawerClosed('drawerCreateChild')"
                >
                    <div>
                        <crm-create
                            ref="drawerCreateChild"
                            drawer="drawerCreate"
                        >
                        </crm-create>
                    </div>  
                </el-drawer>

                <el-drawer
                    :with-header="false"
                    :visible.sync="drawerUpdate"
                    size="70%"
                    ref="drawerUpdate"
                    class="body_scroll_70" 
                    @opened="drawerOpened('drawerUpdateChild')"
                    @closed="drawerClosed('drawerUpdateChild')"
                >
                    <crm-update
                        :selectedItem="selectedItem"
                        ref="drawerUpdateChild"
                        drawer="drawerUpdate"
                    ></crm-update>
                </el-drawer>

                <el-drawer
                    :with-header="false"
                    :visible.sync="crmShow"
                    size="80%"
                    ref="drawerShow"
                    @opened="drawerOpened('drawerShowChild')"
                    @closed="drawerClosed('drawerShowChild')"
                >
                    <crm-show
                        :selectedItem="selectedItem"
                        ref="drawerShowChild"
                        drawer="drawerShow">
                    
                    </crm-show>
                </el-drawer>

                <el-drawer
                    :with-header="false"
                    :visible.sync="priceList"
                    class="body_scroll_70 bg-se"
                    :append-to-body="true"
                    size="70%"
                    ref="priceList"
                    @opened="drawerOpened('priceListChild')"
                    @closed="drawerClosed('priceListChild')"
                    >
                        <price-list
                            :selectedItem="selectedItem"
                            ref="priceListChild"
                            drawer="priceList"
                        ></price-list>
                </el-drawer>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import CrmShow from "./components/show.vue";
import selectClientType from "@/components/inventory/select-client-type";
import selectClientByClientType from "@/components/inventory/select-client-by-client-type";
import selectClientGender from "@/components/inventory/select-client-gender";
import selectCountry from "@/components/inventory/select-country";
import selectCity from "@/components/inventory/select-city";
import priceList from "./components/price-list";

import list from "@/utils/mixins/list";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "clients",
    mixins: [list],

    components: {
        CrmCreate,
        CrmUpdate,
        Pagination,
        selectClientType,
        selectClientGender,
        selectClientByClientType,
        selectCountry,
        selectCity,
        CrmShow,
        priceList,
    },

    data() {
        return {
            appModal: false,
            crmShow: false,
            sarcheBlocks: "",
            priceList: false,
        };
    },
    computed: {
        ...mapGetters({
            permissions: "auth/permissions",
            list: "clients/list",
            columns: "clients/columns",
            pagination: "clients/pagination",
            filter: "clients/filter",
            sort: "clients/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "clients/index",
            setPagination: "clients/setPagination",
            updateSort: "clients/updateSort",
            updateFilter: "clients/updateFilter",
            updateColumn: "clients/updateColumn",
            updatePagination: "clients/updatePagination",
            show: "clients/show",
            empty: "clients/empty",
            delete: "clients/destroy",
            refreshData: "clients/refreshData",
        }),
        getSelectedForShow(client){
            this.selectedItem = client;
            this.crmShow = true;
        },
        handleCommand(command) {
            this.selectedItem = command.model;
           if (command.action === "delete") {
                this.$confirm(
                    this.$t('message.do_you_really_want_to_do_this'),
                    this.$t("message.warning"), {
                        confirmButtonText: this.$t("message.yes"),
                        cancelButtonText: this.$t("message.no"),
                        type: "warning"
                    }
                    )
                    .then(() => {
                        this.destroy(command.model);
                    })
                    .catch(() => {
                        this.$message({
                            type: "warning",
                            message: this.$t("message.operation_canceled")
                        });
                    });
            } else if(command.action === "edit") {          
                this.edit(command.model);
            } else if(command.action === "price_list") {          
                this.priceList = true;
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("clients/EMPTY_LIST");
        next();
    },
};
</script>

